import { scaleMobile } from "../..";
import { calculateRgba } from "../../..";

/**
 * This method needs to be ported to Banner.php#wp_footer, too!
 */
function bannerOverlayInlineStyle(_ref) {
  let {
    layout: {
      type,
      overlay,
      overlayBg,
      overlayBgAlpha,
      overlayBlur
    },
    design: {
      fontSize
    },
    customCss: {
      antiAdBlocker
    },
    mobile,
    pageRequestUuid4
  } = _ref;
  let backdropFilter = "none";
  if (process.env.PLUGIN_CTX === "pro") {
    /* onlypro:start */
    if (overlay) {
      backdropFilter = `blur(${overlayBlur}px)`;
    }
    /* onlypro:end */
  }

  return {
    className: antiAdBlocker === "y" ? undefined : `rcb-bann3r rcb-bann3r-${scaleMobile(mobile, pageRequestUuid4, type, "banner")} ${overlay ? "" : "overlay-deactivated"}`,
    style: {
      background: overlay ? calculateRgba(overlayBg, overlayBgAlpha) : "none",
      backdropFilter,
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 999999,
      pointerEvents: overlay ? "all" : "none",
      fontSize: scaleMobile(mobile, pageRequestUuid4, +fontSize),
      filter: "none",
      maxWidth: "100vw",
      maxHeight: "100vh"
    }
  };
}
export { bannerOverlayInlineStyle };